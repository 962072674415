import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../comp/Header";
import { Edit, Facebook, LinkedIn } from "@material-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import { getCompanyDetails } from "../../controller/companyController";
import { handleControllerResultException } from "../../utils/exceptionHandler";
import { ArrowBackIos } from "@mui/icons-material";

/**
 * @type {import("../../model/CompanyModel").CompanyModel}
 */
const companyType = {
    name: "",
    companyEmail: "",
    companyFounder: "",
    companyCEO: "",
    contactPerson: "",
    contactEmail: "",
    contactNumber: "",
    companyEmployeeRange: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    companyTagLine: "",
    companyDescription: "",
    companyLogo: "",
    companyUrl: "",
    workingSector: "",
    facebookUrl: "",
    establishDate: "",
    id: 0,
    linkedinUrl: "",
};

export default function Company() {
    const [companyDetails, setCompanyDetails] = useState(companyType);
    const navigate = useNavigate();

    const handleCompanyDetails = useCallback(async () => {
        const res = await getCompanyDetails();
        if (res[0] !== null) {
            setCompanyDetails(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, []);

    useEffect(() => {
        handleCompanyDetails();
    }, []);

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ margin: "auto", padding: "2rem 0rem" }}>
                <Box display={"flex"} justifyContent={"space-between"} marginBottom={"3rem"}>
                    <Button
                        onClick={() => navigate(-1)}
                        variant="contained"
                    >
                        <ArrowBackIos fontSize="small" />
                        Return
                    </Button>
                    <Link to="/company/form">
                        <Button variant="contained">
                            <Edit fontSize="small" style={{ marginRight: "10px" }} />
                            Edit
                        </Button>
                    </Link>
                </Box>

                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Avatar
                                src={companyDetails.companyLogo}
                                alt={companyDetails.name}
                                sx={{ height: "80px", width: "80px" }}
                            />
                            <Typography variant="h5" sx={{ marginLeft: "1rem" }}>
                                {companyDetails.name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography fontSize={"14px"}>Company Founder</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.companyFounder}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "center", xs: "right" } }}
                    >
                        <Typography fontSize={"14px"}>Company CEO</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.companyCEO}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "right", xs: "left" } }}
                    >
                        <Typography fontSize={"14px"}>Company Email</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.companyEmail}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "left", xs: "right" } }}
                    >
                        <Typography fontSize={"14px"}>Company Tag Line</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.companyTagLine}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "center", xs: "left" } }}
                    >
                        <Typography fontSize={"14px"}>Working sector</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.workingSector}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4} sx={{ textAlign: "right" }}>
                        <Typography fontSize={"14px"}>Established Date</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.establishDate}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography fontSize={"14px"}>Cotact Person</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.contactPerson}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "center", xs: "right" } }}
                    >
                        <Typography fontSize={"14px"}>Contact Email</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.contactEmail}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "right", xs: "left" } }}
                    >
                        <Typography fontSize={"14px"}>Company Number</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.contactNumber}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "left", xs: "right" } }}
                    >
                        <Typography fontSize={"14px"}>Employee range</Typography>
                        <Typography fontWeight={"600"}>
                            {companyDetails.companyEmployeeRange}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{ textAlign: { md: "center", xs: "left" } }}
                    >
                        <Typography fontSize={"14px"}>Country</Typography>
                        <Typography fontWeight={"600"}>{companyDetails.country}</Typography>
                    </Grid>
                    <Grid item xs={6} md={4} sx={{ textAlign: "right" }}>
                        <Typography fontSize={"14px"}>State</Typography>
                        <Typography fontWeight={"600"}>{companyDetails.state}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontSize={"14px"}>City</Typography>
                        <Typography fontWeight={"600"}>{companyDetails.city}</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <Typography fontSize={"14px"}>Pin Code</Typography>
                        <Typography fontWeight={"600"}>{companyDetails.pin}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={"14px"}>Address</Typography>
                        <Typography fontWeight={"600"}>{companyDetails.address}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {companyDetails.linkedinUrl !== "" && (
                            <a href={companyDetails.linkedinUrl} target="_blank">
                                <Button variant="contained">
                                    <LinkedIn />
                                    LinkedIn
                                </Button>
                            </a>
                        )}
                        {companyDetails.facebookUrl !== "" && (
                            <a
                                href={companyDetails.facebookUrl}
                                target="_blank"
                                style={{ marginLeft: "1rem" }}
                            >
                                <Button variant="contained">
                                    <Facebook />
                                    Facebook
                                </Button>
                            </a>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
