import LogImg from '../Login/LoginImg.png'
import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();

const ForgotPass = () => {

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  useEffect(() => {}, [email]);

  async function SendOtp(e) {
    e.preventDefault();
    let data = { email };
    axios
      .post("auth/user/forgot/password", data, {})
      .then((res) => {
        console.log(res.data);
        navigate({
          pathname: "/EnterOtp",
          state: { token: res.data.token, email: email },
        });
        cookies.set("email", data.email);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const [companyName, setCompanyName] = useState("");
  const [tagline, setTagline] = useState("");
  const [terms, setTerms] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  const GetDetails = () => {
    axios
        .get(
            `profile/landing-page`,
        )
        .then((res) => {
          setCompanyName(res.data.business_details[0].name);
          setTagline(res.data.business_details[0].tag_line);
          setTerms(res.data.business_details[0].terms);
          setPrivacyPolicy(res.data.business_details[0].privacy_policy);
        })
        .catch((err) => {
          console.log("error: ", err)
        })
  };

  useEffect(() => {
    GetDetails();
  }, []);

  return (
    <>
    <div className='h-[99vh] grid place-items-center'>
    <div className=' md:flex'>
        <div className="hidden md:flex md:flex-col w-1/2">
        <div className='flex md:mt-2'>
          <div className='md:text-lg lg:text-2xl xl:text-3xl lg:mt-1 font-semibold '>
          Welcome to 
          </div>
          <div  className='ml-1  md:text-xl lg:text-3xl xl:text-4xl font-semibold text-[#0865B6]'>
              {companyName}
          </div>
        </div>
        <div className='text-neutral-500 md:text-sm lg:text-lg'>{tagline}</div>
        
        <div className="overflow-hidden w-[40vw]">
        <img className="w-0 md:w-full px-4" src={LogImg} alt="" />
        </div>
        <div className='grid justify-end'>
        <div className='mt-2 text-xs lg:text-base text-slate-500'>
        Powered by HRI @MyBizmo
        </div>
        </div>
        </div>
        <div className="border-[3px] py-5 px-5  md:w-[40vw] w-[90vw] rounded-lg border-[#0865B6] md:ml-20">
          <div className='grid grid-cols-1 gap-y-4 my-10'>
          <div className="text-2xl md:text-3xl font-bold">
          Forgot Password
          </div>
          <div  className='mt-2 xl:mt-6'>
          
     
        <TextField
          className='w-full '
          label="Email"
          placeholder="Enter Email ID"
          id="outlined-size-small"
          onChange={(e) => setEmail(e.target.value)}
        />
          </div>
         
        
          <div className='mt-6 xl:mt-6'>
          <Button onClick={SendOtp} className='w-full' variant="contained">Send</Button>
          </div>
              <div>
                  <Button
                      fullWidth
                      variant="outlined"
                      style={{borderColor:"#08665B6", color:"#0865B6"}}
                      onClick={() => navigate("/")}
                  >
                      Already have an account? Sign In
                  </Button>
              </div>
        </div>
        </div>
        </div>
        </div>
    
    </>
  )
}

export default ForgotPass