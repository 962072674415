import React, { useEffect, useState } from "react";
import Header from "../../comp/Header";
import { selectHeader } from "../features/HeaderSlice";
import { useSelector } from "react-redux";
import comp from "./profile.jpeg";
import { BsFacebook } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

const cookies = new Cookies();

const Profile = () => {
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [empId, setEmpId] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [about, setAbout] = useState("");
  const [image, setImage] = useState("");
  const [country, setCountry] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  async function getData() {
    await axios
      .get(`auth/user/profile`, {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        const profileDetail = resp.data.user.profile;
        setEmail(resp.data.user.email);
        setId(resp.data.user.id);
        setName(resp.data.user.profile.name);
        cookies.set("profileName", resp.data.user.profile.name);
        setPhone(resp.data.user.profile.otp_phone);
        setDob(resp.data.user.profile.dob);
        setEmpId(resp.data.user.profile.employee_id);
        setRole(resp.data.user.profile.role);
        setGender(resp.data.user.profile.gender);
        setAbout(resp.data.user.profile.about_you);
        setImage(resp.data.user.profile.image);
        setCountry(profileDetail.country !== null ? profileDetail.country : "");
        setPinCode(profileDetail.pin !== null ? profileDetail.pin : "");
        setState(profileDetail.state !== null ? profileDetail.state : "");
        setCity(profileDetail.city !== null ? profileDetail.city : "");
        setAddress(profileDetail.address !== null ? profileDetail.address : "");
        cookies.set("profileImage", resp.data.user.profile.image);
        cookies.set("otp", resp.data.user.profile.otp);
        cookies.set("id", resp.data.user.profile.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const currentState = useSelector(selectHeader);
  return (
    <>
      <Header />

      <Container maxWidth="md" sx={{ margin: "4rem auto" ,  marginBottom: "4rem", }}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <img
                src={image ? image : comp}
                alt=""
                width="100px"
                height="100px"
                style={{
                  borderRadius: "50%",
                  objectFit: "fill",
                  width: "100px",
                  height: "100px",
                }}
              />
              <Typography
                marginLeft={"10px"}
                fontSize={{ md: "1.5rem", xs: "1.2rem" }}
                variant="body1"
              >
                {name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end">
              <Link to="/EditProfile">
                <Button variant="contained">
                  Edit <AiOutlineEdit />
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item md={4} xs={6}>
            <Typography>Email</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {email}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={6}
            sx={{ textAlign: { md: "center", xs: "right" } }}
          >
            <Typography>Phone no</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {phone}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={6}
            sx={{ textAlign: { md: "right", xs: "left" } }}
          >
            <Typography>Date of birth</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {dob}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={6}
            sx={{ textAlign: { md: "left", xs: "right" } }}
          >
            <Typography>Employee Id</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {empId}
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            xs={6}
            sx={{ textAlign: { md: "center", xs: "left" } }}
          >
            <Typography>Designation</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {role}
            </Typography>
          </Grid>
          <Grid item md={4} xs={6} sx={{ textAlign: "right" }}>
            <Typography>Gender</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {gender}
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}>
            <Typography>State</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {state}
            </Typography>
          </Grid>
          <Grid item md={4} xs={6} sx={{textAlign:{md:"center", xs:"right"}}}>
            <Typography>Pincode</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {pinCode}
            </Typography>
          </Grid>
          <Grid item md={4} xs={6}  sx={{textAlign:{md:"right", xs:"left"}}}>
            <Typography>Country</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {country}
            </Typography>
          </Grid>
          <Grid item md={4} xs={6} sx={{textAlign:{md:"left", xs:"right"}}}>
            <Typography>City</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {city}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Address</Typography>
            <Typography fontSize={20} fontWeight={"bold"}>
              {address}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>About</Typography>
            <Typography fontSize={18}>{about}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" marginBottom={5}>
              <BsFacebook
                color={blue[800]}
                style={{ marginRight: "1rem" }}
                fontSize={"2rem"}
              />
              <BsLinkedin
                color={blue[800]}
                fontSize={"2rem"}
                style={{ marginRight: "1rem" }}
              />
              <BsGithub fontSize={"2rem"} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Profile;
