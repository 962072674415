export const HTTPStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500,
    };

    export const API = {
        brodcastMessage: {
            broadcastList: "hri_company/broadcast-list"
        },
        message: {
            getMessage: "/hri_company/message",
            adminMessages: "/hri_company/adminemail",
            senMessage: "/hri_company/message/send",
            deleteMessage: "/hri_company/message/delete"
        }
    };
    
export const ObjType = {
    businessData: "BusinessData",
    httpResponse: "HttpResponse",
    };