/* eslint-disable jsx-a11y/alt-text */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmailIcon from "@mui/icons-material/Email";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Badge,
  Dialog,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { alpha, styled } from "@mui/material/styles";
import { Drawer, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Keyboard, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Cookies from "universal-cookie";
import logo from "./HRI-logo.jpeg";
import "./Header.scss";
// import Logout from "../Pages/Logout/Logout"
import { CameraAlt, VerifiedUser } from "@material-ui/icons";
import { CorporateFare } from "@mui/icons-material";
import { setHeaderData } from "../features/HeaderDataSlice";
import { handleControllerResultException, handleException } from "../utils/dataFetchHelper";
import Notification from "./Notification/Notification";
import "./Sidebar.scss";
import WarningModal from "./WarningModal";
// import ProfileLogo from "../Images/user.png";

const cookies = new Cookies();

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Header = ({ username }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const currentState = useSelector(selectHeader);
  const [currentState, setCurrentState] = useState(false);

  const [broadcastData, setBroadcastData] = useState([]);
  const [visible1, setVisible1] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [openModal , setOpenModal] = useState(false)

  // const handleOpen = () => {
  //   axios
  //     .get("chat/connect", {
  //       headers: {
  //         Authorization: "Token " + cookies.get("token"),
  //       },
  //     })
  //     .then((resp) => {
  //       console.log("resp", resp.data.redirect_url);
  //       window.open(resp.data.redirect_url, "_blank", "noopener,noreferrer");
  //     })
  //     .catch((error) => { });
  // };

  const Logout = () => {
    axios
      .post(
        `auth/user/logout`,
        {
          user_id: localStorage.getItem("user"),
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((res) => {
        cookies.remove("token");
        toast.success("Successfully logged out", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#1ab394", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#BAE8DE" },
        });
        navigate("/");
      })
      .catch((err) => {
        console.log("Error: ", err);
        toast.error("Error logging out", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          style: { backgroundColor: "#FF6347", color: "white" },
          icon: false,
          progressStyle: { backgroundColor: "#FFB1A3" },
        });
      });
  };


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const visible = () => {
  //   dispatch(
  //     showing({
  //       show: true,
  //     })
  //   );

  //   if (currentState?.show === true) {
  //     dispatch(hiding());
  //   }
  // };

  // const visible = () => {

  // }

  const GetDetails = () => {

    axios
      .get(`/hri_company/header-list`, {
        headers: {
          Authorization: `Token ${cookies.get("token")}`,

        }
      })
      .then((res) => {
        dispatch(setHeaderData(res.data))
      })
      .catch((err) => {
        const errRes = handleException(err);
        handleControllerResultException(errRes, navigate);
      });

  };
  /**
   * @type {import("../features/HeaderDataSlice").HeaderData}
   */
  const headerData = useSelector(state => state.headerData);

  useEffect(() => {
    if (headerData.isEmpty) {
      GetDetails();
    }
  }, [headerData.isEmpty]);

  const showDrawer = () => {
    setVisible1(true);
  };

  const onClose = () => {
    setVisible1(false);
  };


  return (
    <>
      <div className="header_main sticky fixed-top top-0 shadow-md z-40">
        <div className="space-x-5 flex mx-4">
          <div className="flex flex-1">
            {currentState?.show === true ? (
              <IconButton>
                {" "}
                <CloseIcon onClick={showDrawer} className="cursor-pointer" />
              </IconButton>
            ) : (
              <IconButton>
                {" "}
                <MenuIcon onClick={showDrawer} className="cursor-pointer" />
              </IconButton>
            )}

            <div className="flex text-[#0865B6] logo-container space-x-2 text-xl px-2 my-auto">
            <Avatar
                        src={headerData.logo || logo}
                        sx={{ width: 38, height: 38 }}
                        className="border-2 border-slate-300 p-[2px] rounded-full"
                      />
                      <div className="font-semibold text-[#0865B6]">{headerData.name || "HR Intelligence"}</div>
            </div>
          </div>
          <div className="flex space-x-3">
            <IconButton
            >
              <Notification/>
            </IconButton>
            <IconButton onClick={() => navigate("/messages/Received")}>
              <Tooltip title="Message">
                <Badge badgeContent={headerData.unread_msg_count} color="primary">
                  <EmailIcon />
                </Badge>
              </Tooltip>
            </IconButton>

            <div className="space-x-2 flex justify-center">
              <IconButton
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              onClick={handleClick}
            >
              <div className="border-1 p-[1px] border-gray-500 rounded-full">
                <Avatar className="h-10 w-10" src={headerData.user_image} alt="user"></Avatar>
                <CameraAlt className="absolute z-1 right-1 bottom-1 bg-white scale-[.6] rounded-lg border-1 border-gray-500" />
              </div>
            </IconButton>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate("/profile")} disableRipple>
                  <PersonIcon />
                  MyProfile
                </MenuItem>
                <MenuItem onClick={() => navigate("/settings")} disableRipple>
                  <SettingsIcon />
                  Settings
                </MenuItem>
                <MenuItem onClick={() => navigate("/company")} disableRipple>
                  <CorporateFare />
                  Company
                </MenuItem>
                <MenuItem onClick={() => navigate("/HelpFaq")} disableRipple>
                  <HelpIcon />
                  Help & FAQ
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => setOpenModal(true)} disableRipple>
                  <LogoutIcon />
                  Logout
                </MenuItem>
                <WarningModal
                  open={openModal}
                  close={() => setOpenModal(false)}
                  title="Logout ?"
                  content="Are you sure you want to Logout ?"
                  onclickfunction={Logout}
                  cancelText="No"
                  agreeText="Yes"
                />
              </StyledMenu>

              <StyledDialog
                onClose={handleCloseDialog}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
              >
                <div>
                  <Swiper
                    cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper"
                  >
                    {broadcastData.map((data) => (
                      <SwiperSlide key={data.id}>
                        <div className="flex justify-start flex-col w-full">
                          <div className="flex justify-between ml-16 mt-5 ">
                            <div className="flex">
                              <Avatar
                                src={cookies.get("profileImage")}
                                className=" mr-4 md:mr-0 border-2 border-slate-500"
                              />
                              <div className="ml-4 mt-1">{data.header}</div>
                            </div>
                            <div className="mr-16 text-lg mt-1">
                              <CircleIcon
                                className={`${data.is_active === true
                                    ? "text-green-500"
                                    : "text-red-500"
                                  }`}
                              />
                            </div>
                          </div>
                          <div className="px-16 pb-16 mt-4">{data.message}</div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </StyledDialog>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title={<div className="text-[#0865B6]">HR Intelligence Company</div>}
        placement={placement}
        closable={false}
        onClose={onClose}
        visible={visible1}
        key={placement}
        width={280}
        extra={
          <Space>
            {/* <Button onClick={onClose}>Cancel</Button> */}
            <IconButton onClick={onClose}>
              <ArrowBackIosNewIcon className="arrow_icon" />
            </IconButton>
          </Space>
        }
      >
        <div className="space-y-2">
          <div
            onClick={() => navigate("/dashboard")}
            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/dashboard"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }`}
          >
            <p className="m-1">
              <DashboardIcon className="mr-5" />
              Dashboard
            </p>
          </div>

          {/* <div
            onClick={() => navigate("/applications")}
            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/applications"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }
                            ${window.location.pathname ===
                "/applications-details"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }`}
          >
            <p className="m-1">
              <InsertDriveFileIcon className="mr-5" />
              Posted Jobs
            </p>
          </div> */}

          <div
            onClick={() => navigate("/posted-jobs/live-jobs")}
            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/posted-jobs/live-jobs"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }
                            ${window.location.pathname === "/jobs/job-details"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }
                            ${window.location.pathname ===
                "/jobs/job-details/edit"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }
                            ${window.location.pathname ===
                "/jobs/add-new-position"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }`}
          >
            <p className="m-1">
              <BusinessCenterIcon className="mr-5" />
              Posted Jobs
            </p>
          </div>

          <div
            onClick={() => navigate("/applications")}
            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
              ${window.location.pathname === "/applications"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }`}
          >
            <p className="m-1 flex">
              <VerifiedUser className="mr-5" />
              Profiles Shared By Admins
            </p>
          </div>
          <div
            onClick={() => navigate("/Calender")}
            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/Calender"
                ? "bg-neutral-100 text-sky-700"
                : ""
              }`}
          >
            <p className="m-1">
              <EventNoteIcon className="mr-5" />
              Calendar
            </p>
          </div>

          {/* <div
            onClick={() => navigate("/user-management")}
            className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/user-management" ? "bg-neutral-100 text-sky-700" : ""}`}
          >
            <p className="m-1 flex">
            <SwitchAccountIcon className="mr-5" />
              User Management
            </p>
          </div> */}
          <div>
            <div className="">
              <div className="space-y-2 ">
                {/* <div
                  onClick={() => navigate("/Settings")}
                  className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                  ${window.location.pathname === "/Settings"
                      ? "bg-neutral-100 text-sky-700"
                      : ""
                    }`}
                >
                  <p className="m-1">
                    <Settings className="mr-5" />
                    Setting
                  </p>
                </div> */}
                {/* <div
                  onClick={() => navigate("/HelpFaq")}
                  className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/HelpFaq" ? "bg-neutral-100 text-sky-700" : ""}`}
                >
                  <p className="m-1 flex">
                    <Help className="mr-5" />
                    Help & FAQ
                  </p>
                </div> */}

                {/* <div onClick={Logout}>
                  <div className="hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold">
                    <p className="m-1 flex">
                      <LogoutSharp className="mr-5" />
                      Logout
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
