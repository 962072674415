import { ArrowBackIos, Save } from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@mui/material";
import { message } from "antd";
import csc from "country-state-city";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../comp/Header";
import Editor from "../../comp/richEditor/Editor";
import {
    createNewJob,
    getRoleSubCategory,
    initialJobFormPageData,
    removeServeyQuestion,
    updateJobDetails,
} from "../../controller/JobController";
import { searchCompany } from "../../controller/companyController";
import useCountryStateCitySelector from "../../hooks/useCountryStateCitySelector";
import useCreateEditor from "../../hooks/useCreateEditor";
import { createJobServerObj } from "../../model/JobModel";
import { handleControllerResultException } from "../../utils/handleException";
import { HTTPStatus } from "../../utils/helperObj";
import Questionnaire from "./Questionnaire";

export default function AddNewJob() {
    const [positionName, setPositionName] = useState("");
    const [experience, setExperience] = useState("");
    const [employmentType, setEmploymentType] = useState("");
    const [salary, setSalary] = useState("");
    const [education, setEducation] = useState("");
    const [educationList, setEducationList] = useState([]);
    const [roleCategoryId, setRoleCategoryId] = useState("");
    const [roleCategoryList, setRoleCategoryList] = useState([]);
    const [roleSubCategoryId, setRoleSubCategoryId] = useState("");
    const [roleSubCategoryList, setRoleSubCategoryList] = useState([]);
    const [functionalArea, setFunctionalArea] = useState("");
    const [skills, setSkills] = useState("");
    const [description, setDescription] = useState("");
    const [hasQuestionnaire, setHasQuestionnaire] = useState(false);
    const [questionnaireTitle, setQuestionnaireTitle] = useState("");
    const [questionnaireDescription, setQuestionnaireDescription] =
        useState("");
    const questionnaireId = useRef(0);
    const [JobQuestionnaire, setJobQuestionnaire] =
        useState(jobQuestionnaireType);
    const [companyList, setCompanyList] = useState(companyListType);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [findCompany, setFindCompany] = useState("");
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();
    const editor = useCreateEditor(description);

    const {
        CountryFiled,
        StateFiled,
        CityFiled,
        cityId,
        countryId,
        stateId,
        setCountryId,
        setCityId,
        setStateId,
    } = useCountryStateCitySelector();


    const handleRoleSubCategory = useCallback(
        async roleId => {
            const res = await getRoleSubCategory(roleId);
            if (res[0] !== null) {
                setRoleSubCategoryList(res[0]);
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    const handleInitialData = useCallback(
        async (id = undefined) => {
            setLoading(true);
            const res = await initialJobFormPageData(id);
            setLoading(false);
            if (res[0] !== null) {
                setEducationList(res[0].educationList);
                setRoleCategoryList(res[0].roleCategoryList);
                if (id) {
                    setPositionName(res[0].jobDetails.positionName);
                    setExperience(res[0].jobDetails.experience);
                    setEmploymentType(res[0].jobDetails.employmentType);
                    setSalary(res[0].jobDetails.salary);
                    setEducation(res[0].jobDetails.education);
                    setFunctionalArea(res[0].jobDetails.functionalArea);
                    setSkills(res[0].jobDetails.skills);
                    setDescription(res[0].jobDetails.jobDescription);
                    setRoleCategoryId(res[0].jobDetails.roleCategoryId);
                    await handleRoleSubCategory(
                        res[0].jobDetails.roleCategoryId
                    );
                    setRoleSubCategoryId(res[0].jobDetails.roleSubCategoryId);
                    if (res[0].jobDetails.locationIds.length > 0) {
                        setCountryId(res[0].jobDetails.locationIds[0]);
                        setStateId(res[0].jobDetails.locationIds[1]);
                        setCityId(res[0].jobDetails.locationIds[2]);
                    }
                    questionnaireId.current = res[0].jobDetails.questionnaireId;
                    setHasQuestionnaire(res[0].jobDetails.hasQuestionnaire);
                    setQuestionnaireTitle(res[0].jobDetails.questionnaireTitle);
                    setQuestionnaireDescription(
                        res[0].jobDetails.questionnaireDescription
                    );
                    setJobQuestionnaire(res[0].jobDetails.JobQuestionnaire);
                    setFindCompany(res[0].jobDetails.companyName);
                    setSelectedCompany(res[0].jobDetails.companyName);
                }
            } else {
                handleControllerResultException(res[1], navigate);
            }
        },
        [navigate]
    );

    async function handleSearchCompany(searchText) {
        const res = await searchCompany(searchText);

        if (res[0] !== null) {
            setCompanyList(res[0]);
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }

    async function handleCreateNewJob() {
        let companyId = 0;
        for (let x of companyList) {
            if (x.name === selectedCompany) {
                companyId = x.id;
            }
        }
        setSalary(true);
        const res = await createNewJob(
            createJobServerObj({
                positionName,
                roleSubCategoryId,
                education,
                jobDescription: editor.getHTML(),
                skills,
                experience,
                employmentType,
                functionalArea,
                hasQuestionnaire,
                JobQuestionnaire,
                questionnaireId: questionnaireId.current,
                location: csc.getCityById(cityId).name,
                questionnaireDescription,
                questionnaireTitle,
                salary,
                company: companyId !== 0 ? companyId : null,
                locationIds: [countryId, stateId, cityId],
            })
        );
        setSaving(false);
        if (res.statusCode === HTTPStatus.CREATED) {
            message.success("Job created successfully");
            navigate(-1);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    async function handleUpdatejobData() {
        let companyId = 0;
        for (let x of companyList) {
            if (x.name === selectedCompany) {
                companyId = x.id;
            }
        }
        setSaving(true);
        const res = await updateJobDetails({
            ...createJobServerObj({
                positionName,
                roleSubCategoryId,
                education,
                jobDescription: editor.getHTML(),
                skills,
                experience,
                employmentType,
                functionalArea,
                hasQuestionnaire,
                JobQuestionnaire,
                location: csc.getCityById(cityId).name,
                questionnaireId: questionnaireId.current,
                questionnaireDescription,
                questionnaireTitle,
                salary,
                company: companyId !== 0 ? companyId : null,
                locationIds: [countryId, stateId, cityId],
            }),
            id,
        });
        setSaving(false);
        if (res.statusCode === HTTPStatus.CREATED) {
            message.success("Update Job");
            navigate(-1)
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    async function handleRemoveQuestion(index, questionId) {
        const res = await removeServeyQuestion(questionId);
        if (res.statusCode === HTTPStatus.CREATED) {
            message.success(res.success);
            const newArr = [...JobQuestionnaire];
            newArr.splice(index, 1);
            setJobQuestionnaire(newArr);
        } else {
            handleControllerResultException(res, navigate);
        }
    }

    useEffect(() => {
        handleInitialData(id);
    }, [handleInitialData, id]);

    useEffect(() => {
        if (roleCategoryId !== "") {
            handleRoleSubCategory(roleCategoryId);
        } else {
            setRoleSubCategoryId("")
        }
    }, [roleCategoryId])
    if (loading) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <CircularProgress size={"2rem"} />
            </Box>
        );
    }

    return (
        <Box>
            <Header />
            <Container maxWidth="lg" sx={{ marginX: "auto", paddingY: "5rem" }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Button
                                onClick={() => navigate(-1)}
                                variant="contained"
                            >
                                <ArrowBackIos fontSize="small" />
                                Return
                            </Button>
                            <Button
                                disabled={saving}
                                variant="contained"
                                onClick={
                                    id
                                        ? handleUpdatejobData
                                        : handleCreateNewJob
                                }
                                sx={{ width: "100px" }}
                            >
                                {saving ? (
                                    <CircularProgress size={"14px"} />
                                ) : (
                                    <>
                                        {" "}
                                        <Save sx={{ marginRight: "10px" }} />
                                        {id ? "Update" : "Save"}
                                    </>
                                )}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            fullWidth
                            label="Position Name"
                            value={positionName}
                            onChange={e => setPositionName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="experience">Experience</InputLabel>
                            <Select
                                labelId="experience"
                                value={experience}
                                onChange={e => setExperience(e.target.value)}
                                label="Experience"
                            >
                                <MenuItem value={"Fresher"}>Fresher</MenuItem>
                                <MenuItem value={"0-1 years"}>
                                    0-1 years
                                </MenuItem>
                                <MenuItem value={"1-2 years"}>
                                    1-2 years
                                </MenuItem>
                                <MenuItem value={"2-3 years"}>
                                    2-3 years
                                </MenuItem>
                                <MenuItem value={"3-4 years"}>
                                    3-4 years
                                </MenuItem>
                                <MenuItem value={"4-5 years"}>
                                    4-5 years
                                </MenuItem>
                                <MenuItem value={"5+ years"}>5+ years</MenuItem>
                                <MenuItem value={"7+ years"}>7+ years</MenuItem>
                                <MenuItem value={"10+ years"}>10+ years</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="employeeType">
                                Employee Type
                            </InputLabel>
                            <Select
                                labelId="employeeType"
                                value={employmentType}
                                onChange={e =>
                                    setEmploymentType(e.target.value)
                                }
                                label="employeeType"
                            >
                                <MenuItem value={"Part-time"}>
                                    Part-time
                                </MenuItem>
                                <MenuItem value={"Part-time(Work from home)"}>
                                    Part-time(Work from home)
                                </MenuItem>
                                <MenuItem value={"Full-time"}>
                                    Full-time
                                </MenuItem>
                                <MenuItem value={"Full-time(Work from home)"}>
                                    Full-time(Work from home)
                                </MenuItem>
                                <MenuItem value={"Contractual (Part-time)"}>
                                    Contractual (Part-time)
                                </MenuItem>
                                <MenuItem value={"Contractual (Full-time)"}>
                                    Contractual (Full-time)
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        {<CountryFiled />}
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <StateFiled />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <CityFiled />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            label="Stipend/Salary"
                            value={salary}
                            onChange={e => setSalary(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="education">Education</InputLabel>
                            <Select
                                labelId="education"
                                value={education}
                                onChange={e => setEducation(e.target.value)}
                                label="Education"
                            >
                                {educationList.map((data, index) => (
                                    <MenuItem value={data} key={index}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="role">Role</InputLabel>
                            <Select
                                labelId="role"
                                value={roleCategoryId}
                                onChange={e =>
                                    setRoleCategoryId(e.target.value)
                                }
                                label="Role"
                            >
                                {roleCategoryList.map(data => (
                                    <MenuItem value={data.id} key={data.id}>
                                        {data.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="roleSubcategory">
                                Role Sub Category
                            </InputLabel>
                            <Select
                                labelId="roleSubcategory"
                                value={roleSubCategoryId}
                                onChange={e =>
                                    setRoleSubCategoryId(e.target.value)
                                }
                                label="Role Sub Catgegory"
                            >
                                {roleSubCategoryList.map(data => (
                                    <MenuItem value={data.id} key={data.id}>
                                        {data.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <TextField
                            label="Functional Area"
                            value={functionalArea}
                            onChange={e => setFunctionalArea(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    {/* <Grid item xs={6} md={4}>
                        <SuggestionTextField
                            options={companyList.map(data => data.name)}
                            searchFunction={handleSearchCompany}
                            setSelectOption={setSelectedCompany}
                            textFieldLabel="Company (optional)"
                            search={findCompany}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            label="Skills (ReactJs, NodeJs, Typescript)"
                            value={skills}
                            onChange={e => setSkills(e.target.value)}
                            fullWidth
                            multiline
                            rows={5}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Editor editor={editor} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={hasQuestionnaire}
                                    onChange={e =>
                                        setHasQuestionnaire(e.target.checked)
                                    }
                                />
                            }
                            label={"Questionnaire (optional)"}
                        />
                    </Grid>
                    {hasQuestionnaire && (
                        <Grid item xs={12}>
                            <Questionnaire
                                title={questionnaireTitle}
                                setTitle={setQuestionnaireTitle}
                                description={questionnaireDescription}
                                setDescription={setQuestionnaireDescription}
                                JobQuestionnaire={JobQuestionnaire}
                                setJobQuestionnaire={setJobQuestionnaire}
                                removeQuestionFunc={handleRemoveQuestion}
                            />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    );
}

//  Question type



/**
 * @type {import("../../models/JobModel").JobQuestionnaire[]}
 */
const jobQuestionnaireType = [];

/**
 * @type {import("../../models/CompanyModel").CompanyModel[]}
 */
const companyListType = [];
