export default class BasicHttpResponse {

    /**
     *  @type {number}
     */
    statusCode;
    /**
     * @type {string | null}
     */
    successMsg;

    /**
     * @type {string | null}
     */
    errorMsg;


    /**
     * @typedef {object} Props
     * @property {number} statusCode
     * @property {string | null} successMsg
     * @property {string | null} errorMsg
     */
    /**
     * @param {Props} props
     */
    constructor({ statusCode, successMsg = null, errorMsg = null }) {
        this.statusCode = statusCode;
        this.successMsg = successMsg;
        this.errorMsg = errorMsg;
    }
}
