import React, { useCallback, useEffect, useState } from "react";


import ArticleIcon from '@mui/icons-material/Article';
import { Box, Modal, Typography } from "@mui/material";
import Button from "@mui/material/Button";
// import {LocalizationProvider, StaticDatePicker} from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dayjs from 'dayjs';
// import TextField from '@mui/material/TextField';
import { green } from "@mui/material/colors";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import axios from "axios";
import { isSameDay } from "date-fns";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import Header from "../../comp/Header";
import { handleControllerResultException, handleException } from "../../utils/dataFetchHelper";
import { selectHeader } from "../features/HeaderSlice";
import image from "./undraw_beach_day_cv97 1.png";


const cookies = new Cookies();

const Calender = () => {

    const navigate = useNavigate();

    const monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthValue = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]

    const dateValue = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15",
        "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]

    const [value, setValue] = useState(dayjs(new Date));

    const [meetDetail, setMeetDetail] = useState([]);
    const [calendarMeet, setCalendarMeet] = useState([]);
    const [nextMeet, setNextMeet] = useState([]);
    const [meetingDates , setMeetingDates] = useState([]);

    const fetchMeetingDate = useCallback(async () => {
        try {
            const res = await axios.get('hri_company/next-meeting-dates', {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            });
            setMeetingDates(res.data.next_dates);
        } catch (err) {
            const errRes = handleException(err);
            handleControllerResultException(errRes, navigate);
        }
    }, []);
    
    useEffect(() => {
        fetchMeetingDate();
    }, [fetchMeetingDate]);

    const meetingDetail = (id) => {
        axios
            .get(
                `hri_company/meeting/${id}`,
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token")
                    }
                }
            )
            .then((res) => {
                console.log("Meeting Data: ", res.data);
                setMeetDetail(res.data);
            })
            .catch((err) => {
                console.log("Error: ", err)
            })
    }

    const getCalendar = (date, month, year) => {
        if (date === undefined && month === undefined && year === undefined) {
            date = dateValue[value.getDate() - 1];
            month = monthValue[value.getMonth()];
            year = value.getFullYear();
        }

        axios
            .get(`hri_company/meeting-by-date/${year}-${month}-${date}`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {
                setCalendarMeet(res.data);
            })
            .catch((err) => {
                const errRes = handleException(err);
                handleControllerResultException(errRes, navigate)
            });
    };

    const nextMeeting = () => {
        axios
            .get(`hri_company/next-meeting`, {
                headers: {
                    Authorization: "Token " + cookies.get("token"),
                },
            })
            .then((res) => {
                if (!res.data.length) {
                    toast.error("No Meeting scheduled ", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
                setNextMeet(res.data);
                setValue(new Date(res.data[0].date));
                getCalendar(
                    res.data[0].date.slice(8, 10),
                    res.data[0].date.slice(5, 7),
                    res.data[0].date.slice(0, 4)
                );
            })
            .catch((err) => { });
    };

    function renderDay(day, _selectedDay, props) {
        const isMarked = meetingDates.some((meetingDate) =>
            isSameDay(new Date(meetingDate), day)
        );
        if (isMarked) {
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <PickersDay {...props} sx={{ backgroundColor: green[200] }} />
                </Box>
            );
        } else {
            return <PickersDay {...props} />;
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const currentState = useSelector(selectHeader);
    
    return (
        <>
            <Header />
            <div>
                <div className=" pb-5  bg-gray-100 px-4 w-full">
                    <div>
                        <div className="bg-white flex flex-col m-1 rounded-lg md:flex-row md:m-4">

                            <div className="text-center md:mx-4 ">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <StaticDatePicker
                                        displayStaticWrapperAs="desktop"
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        value={value}
                                        renderDay={renderDay}
                                        onChange={(newValue) => {
                                    setValue(newValue);
                                    getCalendar(
                                        dateValue[newValue.getDate() - 1],
                                        monthValue[newValue.getMonth()],
                                        newValue.getFullYear()
                                    );
                                }}
                                    // renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="bg-[#0865B6] rounded-lg text-white px-4 flex-grow">

                                <div className="flex pt-3">
                                    <div className="text-5xl">
                                        <p className="flex justify-end pr-2">
                                            {/* {dateValue[value.getDate() - 1]} */}
                                        </p>
                                    </div>
                                    <div className="flex flex-col">
                                        <p>
                                            {/* {monthList[value.getMonth()]} */}
                                        </p>
                                        <p>
                                            {/* {value.getFullYear()} */}
                                        </p>
                                    </div>
                                </div>


                                {calendarMeet.length > 0 ? <div className="pt-3 pb-8 px-4 space-y-2">
                                    <p className="text-lg">
                                        MEETINGS
                                    </p>
                                    {calendarMeet.map((meet) => (
                                        <div key={meet.id}>

                                            <div
                                                className="flex flex-col px-2 py-1 gap-2 bg-[#FDFDFD80] text-[16px] md:flex-row md:gap-6">
                                                <p>
                                                    {meet.start_time} - {meet.end_time}
                                                </p>
                                                <p>
                                                    {meet.title}
                                                </p>
                                            </div>

                                        </div>
                                    ))}
                                </div> : <div className="flex justify-center pt-4">
                                    <p className="text-xl ">
                                        No meetings Scheduled for today
                                    </p>
                                </div>}

                            </div>


                        </div>

                        <div>

                            {calendarMeet.length > 0 ?
                                <div className="grid grid-cols-1 md:grid-cols-2">
                                {calendarMeet.map((data, i) => {
                                  return (
                                    <div className="bg-white rounded-lg relative mx-4 my-3" key={data.id}>
                  
                                      <div className="absolute top-0 left-0 flex items-center justify-center bg-[#4F95FD]
                                                  text-white w-24 h-12 text-base font-semibold rounded-br-lg rounded-tl-lg"
                                      >
                                        {moment(data.date).format("dddd")}
                                      </div>
                                      <div className="pt-16 px-5 pb-3">
                                        <p className="text-2xl py-1 flex items-center gap-2 font-semibold">
                                          <ArticleIcon className="mr-2" fontSize="medium" sx={{ color: "#A9A9A9" }} />
                                      
                                          {data.title}
                                        </p>
                                        <div className="text-gray-600">
                                        <p> Company: {data.company_name}</p>
                                        <p className="">User : {data.user_name}</p>
                                        </div>
                  
                                        <h3 className="text-lg font-semibold text-gray-500">Meeting details</h3>
                                        
                                       
                                        <div className="grid grid-cols-2">
                                          <div className="">
                                            <p>
                                              Meeting start at : {moment(data.start_time, "HH:mm:ss").format("hh mm A")}
                                            </p>
                                          </div>
                                          <div className="flex justify-end mr-6">
                                            <p>Duration : {data.duration}min</p>
                                          </div>
                                          <div className="col-span-full">
                                            {data.cancel ?
                                              <div className="">
                                                <p className="">
                                                  Cancel Reason : {data.cancel_reason}
                  
                                                </p>
                                              </div>
                  
                                              : <div className="flex justify-between mr-6">
                                                <p>Meeting password : {data.meeting_password ? data.meeting_password : "No password required"}</p>
                  
                                             <a href={data.meeting_link} target="_blank" className={`font-semibold no-underline text-blue-500 ml-3`} ><Button variant="contained" size="small" >Join</Button></a>
                  
                                              </div>}
                                          </div>
                  
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div> : <div className="flex md:h-[30vh] overflow-hidden
                    sm:h-[auto] flex-col justify-center md:flex-row">
                                    <img src={image} alt="" className='md:p-2 p-5' />
                                    <div className="flex-col px-3 self-center">
                                        <p className="text-3xl font-bold">
                                            No events or meetings today
                                        </p>
                                        {/* <p className=" text-xl">
                                            <span
                                                onClick={() => {
                                                    nextMeeting();
                                                }}
                                                className="text-[#0865B6] cursor-pointer">
                                                Click here {" "}
                                            </span>
                                            to go to next day with the shedule.
                                        </p> */}
                                    </div>
                                </div>}

                        </div>
                    </div>

                    {meetDetail.map((detail) => {
                        const detailDate = new Date(detail.date);
                        return (
                            <Modal
                                open={open}
                                onClose={handleClose}
                                className="flex justify-center items-center"
                            >
                                <Box className="space-y-2 bg-white p-5 rounded-lg w-5/6 md:w-4/6 lg:w-2/6">
                                    <div>
                                        <Typography id="modal-modal-title" variant="h5" component="h2">
                                            Title: {detail.title}
                                        </Typography>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Company: {detail.company ? detail.company : "null"}
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Note: {detail.note}
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Date: {detailDate.getDate()}&nbsp;{monthList[detailDate.getMonth()]}, {detailDate.getFullYear()}
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            Time: {detail.start_time} - {detail.end_time}
                                        </Typography>
                                    </div>
                                </Box>
                            </Modal>
                        )
                    })}
                </div>
            </div>

        </>
    )
}

export default Calender;