import axios from "axios";
import { handleException } from "../utils/exceptionHandler";
import { API } from "../utils/constants";
import Cookies from "universal-cookie";
import BasicHttpResponse from "../model/BasicHttpResponse";
import { handleExceptions } from "../utils/handleException";
import { BackendAPI } from "../constants/backendAPI";
import { createCompanyMailObj, createCompanyObj } from "../model/CompanyModel";

const cookies = new Cookies();

/**
 *
 * @param {string} searchText
 * @returns {Promise<[import("../models/CompanyModel").CompanyModel[]|null, import("../models/HttpResponse").HttpResponse | null]>}
 */
export async function getCompanyMailList(searchText = "") {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.company.companyMailList, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
            params: {
                keyword: searchText,
            },
        });
        const companyArr = [];
        for (let x = 0; x < res.data.length; x++) {
            companyArr.push(createCompanyMailObj(res.data[x]));
        }
        result[0] = companyArr;
        return result;
    } catch (err) {
        result[1] = handleExceptions(err);
        return result;
    }
}



/**
 * 
 * @param {string} companyName
 * @returns {Promise<[import("../models/CompanyModel").CompanyModel[]|null, import("../models/HttpResponse").HttpResponse | null]>} 
 */
export async function searchCompany(companyName) {
    const result = [null, null];
    try {
        const res = await axios.get(BackendAPI.company.companyList, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
            params: {
                keyword: companyName
            }
        });
        const companyArr = [];
        for (let data of res.data) {
            companyArr.push(createCompanyObj(data))
        }
        result[0] = companyArr;
        return result
    } catch (err) {
        result[1] = handleExceptions(err);
        return result;
    }
}

/**
 * @returns {Promise<[import("../model/CompanyModel").CompanyModel|null, import("../model/BasicHttpResponse").default | null]>}
 */
export async function getCompanyDetails() {
    const result = [null, null];
    try {
        const res = await axios.get(API.company.companyDetails, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            },
        });
        result[0] = createCompanyObj(res.data);
        return result;
    } catch (err) {
        result[1] = handleException(err);
        return result;
    }
}

/**
 *
 * @param {import("../model/CompanyModel").CompanyModel} param
 */
export async function updateCompanyDetails({
    id,
    name,
    companyEmail,
    address,
    city,
    companyCEO,
    companyDescription,
    companyEmployeeRange,
    companyFounder,
    companyLogo,
    companyTagLine,
    companyUrl,
    contactEmail,
    contactNumber,
    contactPerson,
    country,
    establishDate,
    facebookUrl,
    linkedinUrl,
    pin,
    state,
    workingSector,
}) {
    const form = new FormData();
    form.append("name", name);
    form.append("contact_email", contactEmail);
    form.append("company_email", companyEmail);
    form.append("company_founder", companyFounder);
    form.append("company_ceo", companyCEO);
    form.append("company_logo", companyLogo);
    form.append("pin", pin);
    form.append("country", country);
    form.append("state", state);
    form.append("city", city);
    form.append("address", address);
    form.append("company_employee", companyEmployeeRange);
    form.append("contact_number", contactNumber);
    form.append("contact_person", contactPerson);
    form.append("contact_email", contactEmail);
    form.append("facebook_url", facebookUrl);
    form.append("linkedin_url", linkedinUrl);
    form.append("company_description", companyDescription);
    form.append("company_url", companyUrl);
    form.append("working_sector", workingSector);
    form.append("established_in", establishDate);
    form.append("company_tagline", companyTagLine);

    try {
        const res = await axios.put(`${API.company.updateCompanyDetails}/${id}`, form, {
            headers: {
                Authorization: `Token ${cookies.get("token")}`,
            }
        })
        return new BasicHttpResponse({ statusCode: res.status, successMsg: "Update successful" })
    } catch (err) {
        return handleException(err);
    }
}