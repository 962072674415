import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CellTowerIcon from '@mui/icons-material/CellTower';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventNoteIcon from '@mui/icons-material/EventNote';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { signOut } from "firebase/auth";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hiding, selectHeader } from "../features/HeaderSlice";
import { auth } from "../firebase";
import './Sidebar.scss';

import Cookies from "universal-cookie";

const cookies = new Cookies();


const Sidebar = () => {
    const currentState = useSelector(selectHeader);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <div className='sidebar_main  '>
           
            <div
                className={`${
                    currentState?.show === true ? "open" : "close"
                } sidebar_parent_div  pl-5 pr-5 pt-2`}
            >
               
                <div className='sidebar_options_div z-40 text-base space-y-2' onClick={() => dispatch(hiding())}>

                    <div
                        onClick={() => navigate("/dashboard")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/dashboard" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <DashboardIcon className="mr-5"/>
                            Dashboard
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/applications")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/applications" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/applications-details" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <InsertDriveFileIcon className="mr-5"/>
                            Applications
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/posted-jobs")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/posted-jobs" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/posted-jobs/job-details" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/posted-jobs/job-details/edit" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/posted-jobs/add-new-position" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <BusinessCenterIcon className="mr-5"/>
                            Jobs
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/broadcast")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/broadcast" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/active" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/in-active" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/request" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/details" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/active-details" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/inactive-details" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/request-details" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/broadcast/message" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <CellTowerIcon className="mr-5"/>
                            Broadcast Message
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/calendar")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/calendar" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <EventNoteIcon className="mr-5"/>
                            Calendar
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/user-management/registered-user")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/user-management/registered-user" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/user-management/unregistered-user" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <SwitchAccountIcon className="mr-5"/>
                            User Management...
                        </p>
                    </div>

                    <hr/>

                    <div
                        onClick={() => navigate("/team-manage/all")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/team-manage/all" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/team-manage/active" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/team-manage/in-active" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/team-manage/add-new-member" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <PeopleAltIcon className="mr-5"/>
                            Team Manage
                        </p>
                    </div>


                    <div
                        onClick={() => navigate("/profile-shared")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/profile-shared" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/team-manage/active" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/team-manage/in-active" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/team-manage/add-new-member" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <FolderSharedIcon className="mr-5"/>
                            Profile Shared by Me
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/companies")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/companies" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/companies/companies-details" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/companies/companies-details/edit" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/companies/companies-details/add" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <CorporateFareIcon className="mr-5"/>
                            Companies
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/financials")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/financials" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/BillDetails" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/PayoutDetails" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/Financials" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/AddBill" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/AddPaymentDetails" ? "bg-neutral-100 text-sky-700" : ""}
                            ${window.location.pathname === "/PaymentDetailsDetails" ? "bg-neutral-100 text-sky-700" : ""}`}
                    >
                        <p className="m-1">
                            <CurrencyRupeeIcon className="mr-5"/>
                            Financials
                        </p>
                    </div>


                    <div
                        onClick={() => {
                            const token = cookies.get("token");
                            cookies.remove("token");
                            signOut(auth).then(() => {
                                navigate("/");
                            }).catch(error => {
                                console.error(error);
                            })
                        }}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold`}
                    >
                        <p className="m-1">
                            <LogoutIcon className="mr-5"/>
                            Logout
                        </p>
                    </div>


                </div>
            </div>
              {/* <div className={`${
                    currentState?.show === true ? "block" : "hidden"
                } bg-black  z-[-90000]`}>asda</div> */}
        </div>
    )
}

export default Sidebar;