import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { RiShareForward2Fill } from "react-icons/ri";
import Header from "../../comp/Header";
// import SharePreview from './SharePreview';
// import logo from "./logo.png";
// import Header from '../../components/Header';
// import Sidebar from '../../components/Sidebar';
// import { Country, State, City }  from 'country-state-city';
// import Spinner from '../Spinner/Spinner';
import { Modal } from "antd";
import { Select as S } from "antd";
import { Autocomplete, Avatar, Typography, TextField, Pagination } from "@mui/material";
import { FilterList } from "@mui/icons-material";
// ************************
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import Spinner from "../Meeting/Spinner";

// import { Button } from '@mui/material';

const cookies = new Cookies();

const status = [
  {
    id: "Applied",
    name: "Applied",
  },
  {
    id: "In-Review",
    name: "In-Review",
  },
  {
    id: "Interview",
    name: "Interview",
  },
  {
    id: "Selected",
    name: "Selected",
  },
];
const Applications = () => {
  const [statusID, setStatusID] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const [OPTIONS, setOPTIONS] = useState([
    
  ]);

  const navigate = useNavigate();

  const [id, setId] = useState("1");
  const [sts, setSts] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [shareData, setShareData] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [resume, setResume] = useState("");
  const [resumeName, setResumeName] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [edFilter, setEdFilter] = useState("");
  const [spFilter, setSpFilter] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState({ state: false, data: [], txt: null });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function getAllData(page = 1) {
    axios
      .get("hri_company/profileshare", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
        params:{
          page,
        }
      })
      .then((resp) => {
        setList(resp.data.results);
        setTotalPages(Math.ceil(resp.data.count / 5));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    axios
      .post(
        `hri_company/application/status-change/${statusId}`,
        {
          status: statusID,
        },
        {
          headers: {
            Authorization: "Token " + cookies.get("token"),
          },
        }
      )
      .then((resp) => {
        toast.info(resp.data.message);
       getAllData()
      })
      .catch((err) => {
        console.log("Status", err.message);
        // toast.warn(err.message)
      });
  }, [statusID]);



  const filterData = () => {
    axios
      .get("hri_company/application-filter-data", {
        headers: {
          Authorization: "Token " + cookies.get("token"),
        },
      })
      .then((resp) => {
        // console.log(resp,"optionssssss");
        setOPTIONS(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
        console.log("errorrrrrrr");
      });
  };
  const CloseFilter = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenFilter(false);
    }
  };

  useEffect(() => {
    getAllData()
    filterData();
  }, []);


  async function searchQuery() {
    if (!selectedItems && !edFilter && !spFilter) return;

    const txt = selectedItems + " " + edFilter + " " + spFilter;
    setSearch({ state: true, data: [], txt });

    const URL = `hri_company/profileshare-filter`;
    const res = await axios.get(URL, {
      headers: {
        Authorization: "Token " + cookies.get("token"),
      },
      params: {
        job_name: selectedItems || "",
        degree: edFilter || "",
        specialization: spFilter || "",
      },
    });

    setSearch((p) => ({ ...p, data: res.data?.results }));
    setTotalPages(Math.ceil(res.data.count / 5));
  }

  function resetSearch() {
    setSelectedItems("");
    setSpFilter("");
    setEdFilter("");
    setSearch({ data: [], state: false, txt: null });
  }

  const dynamicData = search.state ? search.data : list;

  return (
    <div className="bg-default-background w-full pb-10">
      <Header />

      {/* <SharePreview
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                id={id}
                data={shareData}
            /> */}

      <div>
        <div className="flex m-1 bg-white">
          <div
            className="bg-white flex-1 space-x-4 text-xl
                     lg:text-xl font-bold text-gray-600"
          >
            <div className="flex p-1">
              <div className="text-blue-600 flex-1">
                <div className="flex">
                  <div className="bg-slate-100 rounded-md md:px-10 sm:px-4 py-2 px-[4] lg:px-20 mx-[auto] mt-[2] md:mx-0 md:mt-0">
                    All Applications
                  </div>
                </div>
              </div>

              {/* Filter Search */}
              <Autocomplete
                size="small"
                freeSolo
                sx={{ minWidth: 250 }}
                options={OPTIONS.map((option) => option)}
                onChange={(e) => setSelectedItems(e.target?.innerText)}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by job" />
                )}
              />
              <div className="my-auto flex gap-2">
                <Button
                  onClick={() => setOpenFilter(true)}
                  // className="max-w-[250px]"
                  variant="outlined"
                >
                  <FilterList />
                  {/* FILTER MORE OPTIONS */}
                </Button>
                <Button onClick={resetSearch} variant="outlined">
                  Clear
                </Button>
                <Button onClick={searchQuery} variant="outlined">
                  Search
                </Button>

                {/* <ButtonBase
                                        className="flex justify-center align-middle"
                                        onClick={resetSearch}
                                    >
                                        <ClearIcon
                                            style={{
                                                color: "#979797",
                                            }}
                                        />
                                    </ButtonBase> */}
                <Dialog
                  disableEscapeKeyDown
                  open={openFilter}
                  onClose={CloseFilter}
                >
                  <DialogTitle>Choose the options</DialogTitle>
                  <DialogContent>
                    <Box
                      component="form"
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="demo-dialog-native">
                          Education
                        </InputLabel>
                        <Select
                          value={edFilter}
                          onChange={(e) => setEdFilter(e.target.value)}
                          input={<OutlinedInput label="Education" />}
                        >
                          <MenuItem value={null}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={""}>Not Specified</MenuItem>
                          <MenuItem value={"B.A"}>B.A</MenuItem>
                          <MenuItem value={"B.Arch"}>B.Arch</MenuItem>
                          <MenuItem value={"BCA"}>BCA</MenuItem>
                          <MenuItem value={"B.B.A/B.M.S"}>B.B.A/B.M.S</MenuItem>
                          <MenuItem value={"B.Com"}>B.Com</MenuItem>
                          <MenuItem value={"B.Ed"}>B.Ed</MenuItem>
                          <MenuItem value={"BDS"}>BDS</MenuItem>
                          <MenuItem value={"BHM"}>BHM</MenuItem>
                          <MenuItem value={"B.Pharma"}>B.Pharma</MenuItem>
                          <MenuItem value={"B.Sc"}>B.Sc</MenuItem>
                          <MenuItem value={"B.Tech/B.E"}>B.Tech/B.E</MenuItem>
                          <MenuItem value={"LLB"}>LLB</MenuItem>
                          <MenuItem value={"MBBS"}>MBBS</MenuItem>
                          <MenuItem value={"Diploma"}>Diploma</MenuItem>
                          <MenuItem value={"BVSC"}>BVSC</MenuItem>
                          <MenuItem value={"BHMS"}>BHMS</MenuItem>
                          <MenuItem value={"B.El.Ed"}>B.El.Ed</MenuItem>
                          <MenuItem value={"B.P.Ed"}>B.P.Ed</MenuItem>
                          <MenuItem value={"BHMCT"}>BHMCT</MenuItem>
                          <MenuItem value={"B.Des."}>B.Des.</MenuItem>
                          <MenuItem value={"BFA"}>BFA</MenuItem>
                          <MenuItem value={"B.U.M.S"}>B.U.M.S</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="demo-dialog-select-label">
                          Specialization
                        </InputLabel>
                        <Select
                          value={spFilter}
                          onChange={(e) => setSpFilter(e.target.value)}
                          input={<OutlinedInput label={"Specialization"} />}
                        >
                          <MenuItem value={null}>
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={""}>Not Specified</MenuItem>
                          <MenuItem value={"Agriculture"}>Agriculture</MenuItem>
                          <MenuItem value={"Automobile"}>Automobile</MenuItem>
                          <MenuItem value={"Aviation"}>Aviation</MenuItem>
                          <MenuItem value={"Bio-Chemistry/Bio-Technology"}>
                            Bio-Chemistry/Bio-Technology
                          </MenuItem>
                          <MenuItem value={"Biomedical"}>Biomedical</MenuItem>
                          <MenuItem value={"Ceramics"}>Ceramics</MenuItem>
                          <MenuItem value={"Chemical"}>Chemical</MenuItem>
                          <MenuItem value={"Civil"}>Civil</MenuItem>
                          <MenuItem value={"Computers"}>Computers</MenuItem>
                          <MenuItem value={"Electrical"}>Electrical</MenuItem>
                          <MenuItem value={"Electronics/Telecommunication"}>
                            Electronics/Telecommunication
                          </MenuItem>
                          <MenuItem value={"Energy"}>Energy</MenuItem>
                          <MenuItem value={"Environmental"}>
                            Environmental
                          </MenuItem>
                          <MenuItem value={"Instrumentation"}>
                            Instrumentation
                          </MenuItem>
                          <MenuItem value={"Marine"}>Marine</MenuItem>
                          <MenuItem value={"Mechanical"}>Mechanical</MenuItem>
                          <MenuItem value={"Metallurgy"}>Metallurgy</MenuItem>
                          <MenuItem value={"Mineral"}>Mineral</MenuItem>
                          <MenuItem value={"Mining"}>Mining</MenuItem>
                          <MenuItem value={"Nuclear"}>Nuclear</MenuItem>
                          <MenuItem value={"Paint/Oil"}>Paint/Oil</MenuItem>
                          <MenuItem value={"Petroleum"}>Petroleum</MenuItem>
                          <MenuItem value={"Plastics"}>Plastics</MenuItem>
                          <MenuItem value={"Production/Industrial"}>
                            Production/Industrial
                          </MenuItem>
                          <MenuItem value={"Textile"}>Textile</MenuItem>
                          <MenuItem value={"Other Specialization"}>
                            Other Specialization
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        CloseFilter();
                        setEdFilter(null);
                        setSpFilter(null);
                      }}
                    >
                      Clear
                    </Button>
                    <Button onClick={CloseFilter}>Ok</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
        <div className="sweet-loading"></div>
        <div className="overflow-auto mx-0 sm:mx-1 rounded-lg  shadow  mb-20">
          {search.txt && (
            <div className=" ml-4 font-bold">
              Filtering by
              <span className="text-blue-700 ml-3">{search.txt}</span>
            </div>
          )}
          {list.length ? (
            <table className="w-full">
              <thead className="bg-slate-200 border-b-2 mt-2 border-gray-300">
                <tr className="text-blue-700">
                  <th className="text-center ml-10 text-lg font-semibold tracking-wide">
                    Applicants
                  </th>
                  <th className="pl-32 text-lg font-semibold tracking-wide ">
                    Degree
                  </th>
                  <th className="pl-4 text-lg font-semibold tracking-wide ">
                    Specialization
                  </th>
                  <th className="text-lg font-semibold tracking-wide truncate ">
                    Shared at
                  </th>

                  <th className="pr-12 text-lg font-semibold tracking-wide ">
                    Details
                  </th>
                  <th className="pr-12 text-lg font-semibold tracking-wide ">
                    Status
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-300 ">
                {dynamicData.map((user, i) => {
                  const date = new Date(user.updated_at.slice(0, 10));
                  const month = date.toLocaleString("default", {
                    month: "long",
                  });

                  return (
                    <tr key={i} className="bg-white h-20">
                      <td className="  h-12   text-base pl-10 text-gray-700 whitespace-nowrap">
                        <div className="flex">
                          <div className="w-12 h-12 ">
                            {user.user_pic ? (
                              <img
                                className="rounded-full w-12 h-12"
                                src={user.user_pic}
                                alt=""
                              />
                            ) : (
                              <Avatar className="scale-[1.2] m-auto mt-1" />
                            )}
                          </div>
                          <div className="ml-4 text-left">
                            <div>
                              {user.user_fname}&nbsp;{user.user_lname}
                            </div>
                            <div className="text-sm text-gray-400">
                              as {user.job_name}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="pl-48 text-base text-gray-700 whitespace-nowrap">
                        <div className="text-sm ml-2 "> {user.user_degree}</div>
                      </td>

                      <td className="pl-[5rem] text-base text-gray-700 whitespace-nowrap">
                        <div className="text-sm ml-2 ">
                          {" "}
                          {user.user_specialization}
                        </div>
                      </td>
                      <td className="pl-5 text-base text-gray-700 whitespace-nowrap">
                        {date.getDate()}&nbsp;
                        {month.slice(0, 3)}&nbsp;
                        {date.getFullYear()}
                      </td>

                      <td className="text-base  whitespace-nowrap">
                        <div
                          onClick={() => {
                            navigate("/ProfileShearedByAdminDetails");
                            cookies.set("profileSharedByAdminID", user.id);
                          }}
                          className=" cursor-pointer    flex justify-start text-black "
                        >
                          <div className="bg-gray-100 rounded-lg p-2">
                            View Profile
                          </div>
                        </div>
                      </td>
                      <td className="flex mt-[19px]   text-base text-gray-700 ">
                        {sts[i] ? (
                          <h className="w-28  px-3  py-2 ">Updating...</h>
                        ) : (
                          <select
                            value={user.application_status}
                            className="border-none w-28 block px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border  border-gray-300 rounded focus:text-gray-700 focus:bg-white focus:border-blue-600  focus:border-none"
                            aria-label="Default select example"
                            onClick={() => setStatusId(user.appli_id)}
                            onChange={(e) => {
                              setStatusID(e.target.value);
                            }}
                          >
                            <option
                              className="h-20 dropdown-item text-sm  py-2 font-normal  block  w-full  whitespace-nowrap bg-transparent text-gray-400 pointer-events-none"
                              disabled
                            >
                              Select Status
                            </option>

                            {status.map((user) => (
                              <option
                                className="h-20"
                                key={user.id}
                                value={user.id}
                              >
                                {user.name}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      {/* <td className="pr-4 text-base text-gray-700 ">
                                                <div className='flex ml-4 cursor-pointer'>
                                                    <RiShareForward2Fill
                                                        onClick={() => {
                                                            handleOpen();
                                                            setId(user.id);
                                                            window.localStorage.setItem('ApplicatonId1', user.id);
                                                            cookies.set("ApplicationUser", user.user);
                                                            cookies.set("ApplicationPosition", user.position);
                                                        }} className='text-xl'
                                                    />
                                                </div>
                                            </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <>
              <Spinner />
            </>
          )}
        </div>

        <Box
          sx={{ display: "flex", justifyContent: "center" }}
          marginBottom={"2rem"}
          marginTop={"2rem"}
        >
          <Pagination
            count={totalPages}
            color="primary"
            shape="rounded"
            onChange={(e, page) => {
            getAllData(page);
            }}
          />
        </Box>
      </div>

      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        title={resumeName}
        footer={false}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <iframe src={resume} width="100%" className="h-96" title="..."></iframe>
      </Modal>
    </div>
  );
};

export default Applications;
