import React, { useCallback, useEffect, useState } from "react";
import { getBroadcastMessages } from "../../controller/NotificationController";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import {
    BroadcastOnPersonal,
    Close
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { handleControllerResultException } from "../../utils/dataFetchHelper";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setNotifications } from "../../features/NotificationSlice";
import { grey } from "@mui/material/colors";
import NotificationCard from "./NotificationCard";

export default function Notification() {
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * @type {import("../../model/BroadcastMessageModel").BroadcastMessageModel[]}
     */
    const notificationList = useSelector(
        (state) => state.notifications.notificationList
    );

    const handleBroadcastMessageList = useCallback(async () => {
        const res = await getBroadcastMessages();
        if (res[0] !== null) {
            dispatch(setNotifications(res[0]));
        } else {
            handleControllerResultException(res[1], navigate);
        }
    }, [navigate, dispatch]);

    useEffect(() => {
        if (notificationList.length === 0) {
            handleBroadcastMessageList();
        }
    }, [handleBroadcastMessageList, notificationList.length]);

    return (
        <Box>
            <button id="notification" onClick={() => setShowNotificationModal(true)}>
                <Tooltip title="Broadcast">
                    <BroadcastOnPersonal />
                </Tooltip>
            </button>
            <Modal
                open={showNotificationModal}
                onClose={() => setShowNotificationModal(false)}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                            lg: "50%",
                            md: "60%",
                            xs: "80%",
                        },
                        maxHeight: "80%",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 0,
                        borderRadius: "5px",
                        overflowY: "auto",
                        backgroundColor: grey[50],
                    }}
                >
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} backgroundColor={"#0865B6"}>
                        <Typography variant="h5" sx={{ marginBottom: "1rem" , p:1 , color:"white",m:2}}>
                            Broadcast{" "}
                        </Typography>
                        <Button onClick={() => setShowNotificationModal(false)}>
                            <Close sx={{ color: "white" }} />
                        </Button>
                    </Box>
                    <Box sx={{p:4}}>
                        {notificationList.length ? (
                            notificationList.map((data) => (
                                <NotificationCard key={data.id} message={data} />
                            ))
                        ) : (
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography variant="h5">No Broadcast Message</Typography>
                            </Box>
                            
                        )}
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}
