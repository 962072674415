
export const API = {
    company: {
        companyDetails: "/hri_company/company",
        updateCompanyDetails: "/hri_company/company/update"
    }
}

export const HTTPStatus = {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    SERVER_ERROR: 500,
};



export const allowedImageExtension = ["image/jpg", "image/png", "image/jpeg", "image/webp"]